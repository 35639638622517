<template>
  <div>
    <div class="wrapper">
      <b-col sm="auto mb-3">
        <b-card border-variant="light">
          <b-container fluid class="image-wrapper">
            <div class="image mt-3">
              <b-img class="rounded-circle" :src="getUserImage()" alt="User Foto"></b-img>
            </div>
            <b-row align-h="center" class="mb-2" v-if="this.resultRecord.tag">
              <b-badge pill variant="success mr-2" v-if="this.resultRecord.tag">
                {{ this.resultRecord.tag }}
              </b-badge>
            </b-row>
            <b-row align-h="center" v-if="this.resultRecord.firstName || this.resultRecord.lastName">
              <b-col>
                <label>{{ this.resultRecord.firstName + " " + this.resultRecord.lastName }}</label>
              </b-col>
            </b-row>
            <b-row align-h="center" v-if="this.resultRecord.email">
              <label
                ><b-link>{{ this.resultRecord.email }}</b-link></label
              >
            </b-row>
            <b-row align-h="center" class="mb-1" v-if="this.resultRecord.id">
              <label>{{ this.resultRecord.id }}</label>
            </b-row>
            <b-row v-if="hasChangePermissions">
              <b-button class="mb-1" variant="info" block @click="updatePermissions">Set admin permissions </b-button>
            </b-row>
            <b-row v-if="hasTransactionsRead">
              <b-button class="mb-1" variant="primary" block @click="onTransactionsBtnClick">Finance </b-button>
            </b-row>
            <!--<b-row v-if="hasDelete && resultRecord.isDeleted === 'No'">
              <b-button class="mb-1" variant="danger" block @click="onDeleteBtnClick">Delete user </b-button>
            </b-row>-->
            <b-row v-if="hasUserManage">
              <b-button class="mb-1" variant="danger" block @click="onDisableBtnClick"
                >{{ getDisableBtnText }}
              </b-button>
            </b-row>
            <b-row v-if="hasUserManage">
              <b-button class="mb-1" variant="danger" block @click="onDisableWithdrawBtnClick"
                >{{ getDisableWithdrawBtnText }}
              </b-button>
            </b-row>
            <b-row v-if="hasDisable2Fa && resultRecord.is2FaEnabled === 'Yes'">
              <b-button class="mb-1" variant="danger" block @click="onDisable2FaBtnClick">Disable 2FA </b-button>
            </b-row>
            <b-row v-if="hasResetLock">
              <b-button class="mb-1" variant="danger" block @click="onResetLockBtnClick">Reset lockout </b-button>
            </b-row>
            <b-row v-if="hasUserManage">
              <b-button class="mb-1" variant="danger" block @click="onReloadCacheBtnClick">Reload cache </b-button>
            </b-row>
            <b-row v-if="!isNotVerifiedUser">
              <b-button class="mb-1" variant="danger" block @click="onResetKycStatusBtnClick">Resubmit KYC </b-button>
            </b-row>
            <b-row v-if="resultRecord.isEmailConfirmed === 'No' && hasUserManage">
              <b-button class="mb-1" variant="danger" block @click="onEmailConfirmBtnClick">Confirm email </b-button>
            </b-row>
            <b-row>
              <b-button class="mb-1" variant="danger" block @click="onInternalBtnClick"
                >Make {{ resultRecord.isInternal === "No" ? "internal" : "external" }}
              </b-button>
            </b-row>
            <b-row>
              <b-button class="mb-2" variant="dark" block @click="onAddCommentBtnClick">Comment </b-button>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
      <b-col>
        <b-card border-variant="light" no-body>
          <div>
            <b-tabs content-class="mt-1" card pills>
              <b-tab title="Description" no-body active>
                <b-row>
                  <b-col class="pr-0">
                    <details-table
                      class="m-0"
                      :ref="refKeyDetailsTable"
                      :data-result-list="this.getDetailsPart(0, 17)"
                      @edit-clicked="onEditProfileBtnClick"
                      @edit-target-clicked="onEditTargetBtnClick"
                      @attach-clicked="onAttachToPartnership"
                    >
                    </details-table>
                  </b-col>
                  <b-col class="pl-0">
                    <details-table
                      :ref="refKeyDetailsTable"
                      :data-result-list="this.getDetailsPart(17)"
                      @edit-clicked="onEditProfileBtnClick"
                      @edit-target-clicked="onEditTargetBtnClick"
                      @attach-clicked="onAttachToPartnership"
                    >
                    </details-table>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab @click="onDemoAccountsLoad()" v-if="hasTradingAccountsRead" no-body>
                <template slot="title">
                  Accounts (Demo)
                  <b-badge pill v-if="demoAccountsTotalRows > 0" variant="secondary">{{
                    demoAccountsTotalRows
                  }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="demoAccountsResult"
                  :fields-list="accountsFields"
                  :is-busy="isLoading"
                  :total-rows="demoAccountsTotalRows"
                  :per-page="demoAccountsPerPage"
                  :need-per-page="true"
                  @load-clicked="onDemoAccountsLoadClicked"
                  @srch-clicked="onDemoAccountsSrchClicked"
                  @pg-clicked="onDemoAccountsPgClicked"
                  @filter-clicked="onDemoAccountsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onRealAccountsLoad()" v-if="hasTradingAccountsRead" no-body>
                <template slot="title">
                  Accounts (Real)
                  <b-badge pill v-if="realAccountsTotalRows > 0" variant="secondary">{{
                    realAccountsTotalRows
                  }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="realAccountsResult"
                  :fields-list="accountsFields"
                  :is-busy="isLoading"
                  :total-rows="realAccountsTotalRows"
                  :per-page="realAccountsPerPage"
                  :need-per-page="true"
                  @load-clicked="onRealAccountsLoadClicked"
                  @srch-clicked="onRealAccountsSrchClicked"
                  @pg-clicked="onRealAccountsPgClicked"
                  @filter-clicked="onRealAccountsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onDealsLoad" no-body v-if="hasTradingAccountsRead">
                <template slot="title">
                  Trades (MT5)
                  <b-badge pill v-if="dealsTotalRows > 0" variant="secondary">{{ dealsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="dealsResult"
                  :fields-list="dealsFields"
                  :is-busy="isLoading"
                  :filters-list="sourceFiltersList"
                  :filters-model="dealsFiltersModel"
                  :total-rows="dealsTotalRows"
                  :per-page="dealsPerPage"
                  :is-filtered="true"
                  :need-per-page="true"
                  :has-details="true"
                  @load-clicked="onDealsLoadClicked"
                  @pg-clicked="onDealsPgClicked"
                  @srch-clicked="onDealsSrchClicked"
                  @filter-clicked="onDealsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onOrdersLoad" no-body v-if="hasTradingAccountsRead">
                <template slot="title">
                  Trades (MT4)
                  <b-badge pill v-if="ordersTotalRows > 0" variant="secondary">{{ ordersTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="ordersResult"
                  :fields-list="ordersFields"
                  :is-busy="isLoading"
                  :filters-list="sourceFiltersList"
                  :filters-model="ordersFiltersModel"
                  :total-rows="ordersTotalRows"
                  :per-page="ordersPerPage"
                  :is-filtered="true"
                  :need-per-page="true"
                  :has-details="true"
                  @load-clicked="onOrdersLoadClicked"
                  @pg-clicked="onOrdersPgClicked"
                  @srch-clicked="onOrdersSrchClicked"
                  @filter-clicked="onOrdersFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab title="Deposits" @click="onDepositsLoad" no-body v-if="hasChecksRead">
                <b-form-checkbox
                  v-model="summaryDepositsChecked"
                  name="check-deposits-button"
                  button-variant="success"
                  class="mr-auto switch-checkbox ml-2"
                  switch
                  size="lg"
                >
                  <b>View summary</b>
                </b-form-checkbox>
                <template slot="title">
                  Deposits
                  <b-badge pill v-if="depositsTotalRows > 0 && !summaryDepositsChecked" variant="secondary">
                    {{ depositsTotalRows }}
                  </b-badge>
                  <b-badge pill v-if="totalDepositsTotalRows > 0 && summaryDepositsChecked" variant="secondary"
                    >{{ totalDepositsTotalRows }}
                  </b-badge>
                </template>
                <common-data-selection-grid
                  v-if="!summaryDepositsChecked"
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="depositsResult"
                  :fields-list="depositsFields"
                  :is-busy="isLoading"
                  :total-rows="depositsTotalRows"
                  :per-page="depositsPerPage"
                  :need-per-page="true"
                  :has-details="true"
                  @sort-clicked="onDepositsSortClicked"
                  @load-clicked="onDepositsLoadClicked"
                  @pg-clicked="onDepositsPgClicked"
                  @srch-clicked="onDepositsSrchClicked"
                  @filter-clicked="onDepositsFilterClicked"
                >
                </common-data-selection-grid>
                <common-data-selection-grid
                  v-if="summaryDepositsChecked"
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="totalDepositsResult"
                  :fields-list="totalDepositsFields"
                  :is-busy="isLoading"
                  :total-rows="totalDepositsTotalRows"
                  :per-page="totalDepositsPerPage"
                  :need-per-page="true"
                  :has-details="true"
                  :need-comment="true"
                  @pg-clicked="onTotalDepositsPgClicked"
                  @srch-clicked="onTotalDepositsSrchClicked"
                  @comment-clicked="onDepositLimitCommentClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onWithdrawalsLoad" no-body v-if="hasChecksRead">
                <b-form-checkbox
                  v-model="summaryWithdrawalsChecked"
                  name="check-withdrawals-button"
                  button-variant="success"
                  class="mr-auto switch-checkbox ml-2"
                  switch
                  size="lg"
                >
                  <b class="mr-auto">View summary</b>
                </b-form-checkbox>
                <template slot="title">
                  Withdrawals
                  <b-badge pill v-if="withdrawalsTotalRows > 0 && !summaryWithdrawalsChecked" variant="secondary">{{
                    withdrawalsTotalRows
                  }}</b-badge>
                  <b-badge pill v-if="totalWithdrawalsTotalRows > 0 && summaryWithdrawalsChecked" variant="secondary"
                    >{{ totalWithdrawalsTotalRows }}
                  </b-badge>
                </template>
                <common-data-selection-grid
                  v-if="!summaryWithdrawalsChecked"
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="withdrawalsResult"
                  :fields-list="withdrawalsFields"
                  :is-busy="isLoading"
                  :total-rows="withdrawalsTotalRows"
                  :per-page="withdrawalsPerPage"
                  :need-per-page="true"
                  :has-details="true"
                  @sort-clicked="onWithdrawalsSortClicked"
                  @load-clicked="onWithdrawalsLoadClicked"
                  @pg-clicked="onWithdrawalsPgClicked"
                  @srch-clicked="onWithdrawalsSrchClicked"
                  @filter-clicked="onWithdrawalsFilterClicked"
                >
                </common-data-selection-grid>
                <common-data-selection-grid
                  v-if="summaryWithdrawalsChecked"
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="totalWithdrawalsResult"
                  :fields-list="totalWithdrawalsFields"
                  :is-busy="isLoading"
                  :total-rows="totalWithdrawalsTotalRows"
                  :per-page="totalWithdrawalsPerPage"
                  :need-per-page="true"
                  :has-details="true"
                  :need-comment="true"
                  :need-grouped="true"
                  @pg-clicked="onTotalWithdrawalsPgClicked"
                  @srch-clicked="onTotalWithdrawalsSrchClicked"
                  @comment-clicked="onPaymentMethodCommentClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onTransactionsLoad" no-body v-if="hasTransactionsRead">
                <template slot="title">
                  Balance history
                  <b-badge pill v-if="transactionsTotalRows > 0" variant="secondary">{{
                    transactionsTotalRows
                  }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="transactionsResult"
                  :fields-list="transactionsFields"
                  :is-busy="isLoading"
                  :total-rows="transactionsTotalRows"
                  :per-page="transactionsPerPage"
                  :need-per-page="true"
                  @load-clicked="onTransactionsLoadClicked"
                  @pg-clicked="onTransactionsPgClicked"
                  @srch-clicked="onTransactionsSrchClicked"
                  @filter-clicked="onTransactionsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onKycLoad" no-body v-if="hasUsersKycRead">
                <template slot="title">
                  KYC
                  <b-badge pill v-if="kycTotalRows > 0" variant="secondary">{{ kycTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="kycResult"
                  :fields-list="kycFields"
                  :is-busy="isLoading"
                  :total-rows="kycTotalRows"
                  :per-page="kycPerPage"
                  :need-details="hasApplicant"
                  :need-per-page="true"
                  :has-details="true"
                  :details-as-table="true"
                  :custom-fields="kycDetailsFields"
                  @load-clicked="onKycLoadClicked"
                  @srch-clicked="onKycSrchClicked"
                  @pg-clicked="onKycPgClicked"
                  @route-clicked="onKycDetailsClicked"
                  @filter-clicked="onKycFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onKycDocsLoad" no-body v-if="hasUsersKycRead">
                <template slot="title">
                  KYC Docs
                  <b-badge pill v-if="kycDocsTotalRows > 0" variant="secondary">{{ kycDocsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="kycDocsResult"
                  :fields-list="kycDocsFields"
                  :is-busy="isLoading"
                  :total-rows="kycDocsTotalRows"
                  :per-page="kycDocsPerPage"
                  :need-per-page="true"
                  :has-details="false"
                  @load-clicked="onKycDocsLoadClicked"
                  @srch-clicked="onKycDocsSrchClicked"
                  @pg-clicked="onKycDocsPgClicked"
                  @filter-clicked="onKycDocsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onAmlLoad" no-body v-if="hasUsersKycRead">
                <template slot="title"> AML</template>
                <div class="mb-3 ml-auto">
                  <b-badge pill variant="secondary">Risk score: {{ riskScore }}</b-badge>
                </div>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="amlResult"
                  :fields-list="amlFields"
                  :is-busy="isLoading"
                  :total-rows="amlTotalRows"
                  :per-page="amlPerPage"
                  :has-details="true"
                  :details-as-table="true"
                  :custom-fields="amlDetailsFields"
                  :need-per-page="true"
                  @load-clicked="onAmlLoadClicked"
                  @srch-clicked="onAmlSrchClicked"
                  @pg-clicked="onAmlPgClicked"
                  @filter-clicked="onAmlFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onBonusesLoad" v-if="hasBonusesRead" no-body>
                <template slot="title">
                  Bonuses
                  <b-badge pill v-if="bonusesTotalRows > 0" variant="secondary">{{ bonusesTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="bonusesResult"
                  :fields-list="bonusesFields"
                  :is-busy="isLoading"
                  :total-rows="bonusesTotalRows"
                  :per-page="perPage"
                  :has-details="true"
                  :details-as-table="true"
                  :custom-fields="bonusesDetailsFields"
                  @srch-clicked="onBonusesSrchClicked"
                  @pg-clicked="onBonusesPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onIpsLoad" no-body>
                <template slot="title">
                  User IP
                  <b-badge pill v-if="ipsTotalRows > 0" variant="secondary">{{ ipsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="ipsResult"
                  :fields-list="ipsFields"
                  :is-busy="isLoading"
                  :total-rows="ipsTotalRows"
                  :per-page="ipsPerPage"
                  :need-per-page="true"
                  @load-clicked="onIpsLoadClicked"
                  @srch-clicked="onIpsSrchClicked"
                  @pg-clicked="onIpsPgClicked"
                  @filter-clicked="onIpsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onLogsLoad" v-if="hasReadLogs" no-body>
                <template slot="title">
                  Action logs
                  <b-badge pill v-if="logsTotalRows > 0" variant="secondary">{{ logsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="logsResult"
                  :fields-list="logsFields"
                  :is-busy="isLoading"
                  :total-rows="logsTotalRows"
                  :per-page="logsPerPage"
                  :need-per-page="true"
                  :has-details="true"
                  @load-clicked="onLogsLoadClicked"
                  @srch-clicked="onLogsSrchClicked"
                  @pg-clicked="onLogsPgClicked"
                  @filter-clicked="onLogsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onKingfinLogsLoad" v-if="hasIbManage" no-body>
                <template slot="title">
                  Affiliate logs
                  <b-badge pill v-if="kingfinLogsTotalRows > 0" variant="secondary">{{ kingfinLogsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="kingfinLogsResult"
                  :fields-list="kingfinLogsFields"
                  :is-busy="isLoading"
                  :total-rows="kingfinLogsTotalRows"
                  :per-page="kingfinLogsPerPage"
                  :need-per-page="true"
                  @load-clicked="onKingfinLogsLoadClicked"
                  @srch-clicked="onKingfinLogsSrchClicked"
                  @pg-clicked="onKingfinLogsPgClicked"
                  @filter-clicked="onKingfinLogsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onCommentsLoad" no-body>
                <template slot="title">
                  Comments
                  <b-badge pill v-if="commentsTotalRows > 0" variant="secondary">{{ commentsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="commentsResult"
                  :fields-list="commentsFields"
                  :is-busy="isLoading"
                  :total-rows="commentsTotalRows"
                  :per-page="commentsPerPage"
                  :has-details="false"
                  @load-clicked="onCommentsLoadClicked"
                  @srch-clicked="onCommentsSrchClicked"
                  @pg-clicked="onCommentsPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onRiskLoad" no-body v-if="hasUsersKycRead" class="risk-wrapper">
                <template slot="title">Risk scoring</template>
                <div
                  v-if="firstStageScore"
                  class="risk-title h5 font-weight-bold"
                  :class="`level-${firstStageScore.level}`"
                >
                  <span>Part 1</span>
                  <span>{{ firstStageScore.level }}: {{ firstStageScore.value }}</span>
                </div>
                <common-data-selection-grid
                  className="riskScoring"
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="firstStageAnswers"
                  :fields-list="riskScoringFields"
                  :is-busy="isLoading"
                  :isSearched="false"
                  :needPagination="false"
                >
                </common-data-selection-grid>
                <div
                  v-if="secondStageScore"
                  class="risk-title h5 font-weight-bold"
                  :class="`level-${secondStageScore.level}`"
                >
                  <span>Part 2</span>
                  <span>{{ secondStageScore.level }}: {{ secondStageScore.value }}</span>
                </div>
                <common-data-selection-grid
                  className="riskScoring"
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="secondStageAnswers"
                  :fields-list="riskScoringFields"
                  :is-busy="isLoading"
                  :isSearched="false"
                  :needPagination="false"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onDuplicatesLoad" v-if="hasDuplicatesManage" no-body>
                <template slot="title">
                  Antifraud
                  <b-badge pill v-if="duplicatesTotalRows > 0" variant="secondary">{{ duplicatesTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="duplicatesResult"
                  :fields-list="duplicatesFields"
                  :is-busy="isLoading"
                  :total-rows="duplicatesTotalRows"
                  :per-page="duplicatesPerPage"
                  :need-per-page="true"
                  :has-details="false"
                  @load-clicked="onDuplicatesLoadClicked"
                  @srch-clicked="onDuplicatesSrchClicked"
                  @pg-clicked="onDuplicatesPgClicked"
                  @cancel-clicked="onCancelDuplicate"
                >
                </common-data-selection-grid>
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </b-col>
    </div>
    <attach-to-partnership-dlg ref="attachToPartnershipDlg"></attach-to-partnership-dlg>
    <edit-permissions-dlg ref="editPermissionsDlg"></edit-permissions-dlg>
    <confirm-dialogue :show-confirm="true" ref="confirmDialogue"></confirm-dialogue>
    <add-comment-dlg ref="addCommentDlg"></add-comment-dlg>
    <router-view />
  </div>
</template>

<script>
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

import EditPermissionsDlg from "../../../components/popups/edit-permissions-dlg";
import AttachToPartnershipDlg from "../../../components/popups/attach-to-partnership-dlg";
import ConfirmDialogue from "../../../components/popups/confirm-dlg";
import AddCommentDlg from "../../../components/popups/add-comment-dlg";
import DetailsTable from "../../../components/grids/details-table";

import { mapGetters } from "vuex";

import {
  convertUserDetails,
  convertIps,
  convertKyc,
  convertAml,
  convertKycDocs,
  convertKingFinLogs,
  convertComments,
  convertDuplicates
} from "../helpers/users-converter";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { convertLogsList } from "@/modules/sections/helpers/logs-converter";
import { convertAccountsList, convertDeals, convertOrders } from "@/modules/sections/helpers/accounts-converter";
import { convertList } from "../helpers/checks-converter";
import { convertTransactionsList } from "@/modules/sections/helpers/transactions-converter";
import { convertUsersBonusesList } from "@/modules/sections/helpers/bonuses-converter";
import { convertDepositsList, convertWithdrawalsList } from "@/modules/sections/helpers/payments-converter";

const PlatformRepository = RepositoryFactory.get(RepositoryName.platform);
const UsersRepository = RepositoryFactory.get(RepositoryName.users);
const ManageRepository = RepositoryFactory.get(RepositoryName.manage);
const TradingAccountsRepository = RepositoryFactory.get(RepositoryName.tradingAccounts);
const ChecksRepository = RepositoryFactory.get(RepositoryName.checks);
const TransactionsRepository = RepositoryFactory.get(RepositoryName.transactions);
const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);
const PaymentsRepository = RepositoryFactory.get(RepositoryName.payments);

const refKeyCommonDataSelectionGrid = "common-data-selection-grid";
const refKeyDetailsTable = "details-table";

export default {
  name: "UserDetails",
  props: ["id"],
  components: {
    CommonDataSelectionGrid,
    DetailsTable,
    EditPermissionsDlg,
    AttachToPartnershipDlg,
    ConfirmDialogue,
    AddCommentDlg
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,
      refKeyDetailsTable,

      sortBy: "",
      sortDesc: true,

      summaryDepositsChecked: false,
      summaryWithdrawalsChecked: false,

      logsCurrentPage: 1,
      kingfinLogsCurrentPage: 1,
      ipsCurrentPage: 1,
      demoAccountsCurrentPage: 1,
      realAccountsCurrentPage: 1,
      dealsCurrentPage: 1,
      ordersCurrentPage: 1,
      depositsCurrentPage: 1,
      totalDepositsCurrentPage: 1,
      totalWithdrawalsCurrentPage: 1,
      withdrawalsCurrentPage: 1,
      kycCurrentPage: 1,
      kycDocsCurrentPage: 1,
      amlCurrentPage: 1,
      transactionsCurrentPage: 1,
      bonusesCurrentPage: 1,
      duplicatesCurrentPage: 1,
      commentsCurrentPage: 1,

      logsPerPage: 20,
      kingfinLogsPerPage: 20,
      ipsPerPage: 20,
      demoAccountsPerPage: 20,
      realAccountsPerPage: 20,
      dealsPerPage: 20,
      ordersPerPage: 20,
      depositsPerPage: 20,
      totalDepositsPerPage: 20,
      totalWithdrawalsPerPage: 20,
      withdrawalsPerPage: 20,
      kycPerPage: 20,
      kycDocsPerPage: 20,
      amlPerPage: 20,
      transactionsPerPage: 20,
      bonusesPerPage: 20,
      duplicatesPerPage: 20,
      commentsPerPage: 20,

      logsMask: "",
      kingfinLogsMask: "",
      ipsMask: "",
      demoAccountsMask: "",
      realAccountsMask: "",
      dealsMask: "",
      ordersMask: "",
      depositsMask: "",
      totalDepositsMask: "",
      totalWithdrawalsMask: "",
      withdrawalsMask: "",
      kycMask: "",
      kycDocsMask: "",
      amlMask: "",
      transactionsMask: "",
      bonusesMask: "",
      duplicatesMask: "",
      commentsMask: "",

      firstName: null,
      lastName: null,
      utmCampaign: null,
      clickId: null,
      url: null,
      number: null,
      dateOfBirth: null,
      gender: null,
      phone: null,
      email: null,
      regDate: null,
      country: null,
      city: null,
      street: null,
      postalCode: null,
      nationality: null,
      billingTarget: null,
      tag: null,
      externalKycStatus: null,
      lastAction: null,

      riskScore: null,
      firstStageScore: null,
      firstStageAnswers: null,
      secondStageScore: null,
      secondStageAnswers: null,

      perPage: 10,
      filter: null,
      filterValue: null,
      logsTotalRows: 0,
      kingfinLogsTotalRows: 0,
      ipsTotalRows: 0,
      demoAccountsTotalRows: 0,
      realAccountsTotalRows: 0,
      dealsTotalRows: 0,
      ordersTotalRows: 0,
      depositsTotalRows: 0,
      totalDepositsTotalRows: 0,
      totalWithdrawalsTotalRows: 0,
      totalWithdrawalsItemRows: 0,
      withdrawalsTotalRows: 0,
      kycTotalRows: 0,
      kycDocsTotalRows: 0,
      amlTotalRows: 0,
      transactionsTotalRows: 0,
      bonusesTotalRows: 0,
      duplicatesTotalRows: 0,
      commentsTotalRows: 0,

      isLoading: false,
      hasApplicant: false,
      applicantUrl: "",

      countries: [],
      usersResult: [],
      logsResult: [],
      kingfinLogsResult: [],
      ipsResult: [],
      demoAccountsResult: [],
      realAccountsResult: [],
      dealsResult: [],
      ordersResult: [],
      depositsResult: [],
      totalDepositsResult: [],
      totalWithdrawalsResult: [],
      withdrawalsResult: [],
      kycResult: [],
      kycDocsResult: [],
      amlResult: [],
      transactionsResult: [],
      bonusesResult: [],
      duplicatesResult: [],
      riskOnboardingResult: [],
      riskSumSubResult: [],
      commentsResult: [],

      resultRecord: {
        id: "",
        utmCampaign: "",
        clickId: "",
        url: "",
        number: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        phone: "",
        email: "",
        regDate: "",
        lastAction: "",
        lockoutWithdrawalsEndDate: "",

        country: "",
        city: "",
        street: "",
        postalCode: "",
        nationality: "",
        billingTarget: "",
        tag: "",
        externalKycStatus: "",
        firstStageRiskLevel: "",
        secondStageRiskLevel: "",

        is2FaEnabled: "",
        isDepositEnabled: "",
        isWithdrawEnabled: "",
        isEmailConfirmed: "",
        isInternal: "",
        isEnabled: "",
        isAdmin: ""
      },
      ipsFields: [
        {
          key: "ip",
          label: "IP",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "countryCode",
          label: "Country Code",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "firstDate",
          label: "First Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "lastDate",
          label: "Last Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ],
      amlDetailsFields: [
        {
          key: "title",
          label: "Question"
        },
        {
          key: "description",
          label: "User's answer"
        }
      ],
      kycDetailsFields: [
        {
          key: "title",
          label: "Type",
          tdClass: "kycDetails text-left pl-4"
        },
        {
          key: "statusTitle",
          label: ""
        },
        {
          key: "reviewType",
          label: ""
        },
        {
          key: "comment",
          label: "",
          tdClass: "text-sm-left"
        }
      ],
      transactionsFields: [
        {
          key: "dateRange",
          type: "date",
          label: "Date&time",
          sortDirection: "desc",
          tdClass: "dateField",
          filtered: true
        },
        {
          key: "typeTitle",
          label: "Type",
          sortDirection: "asc",
          tdClass: "actionsClass",
          filtered: false
        },
        {
          key: "description",
          label: "Description",
          sortDirection: "asc",
          tdClass: "responsive-field",
          filtered: false
        },
        {
          key: "amount",
          label: "Amount",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "sourceBefore",
          label: "Balance before (Source)",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "sourceAfter",
          label: "Balance after (Source)",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "destinationBefore",
          label: "Balance before (Destination)",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "destinationAfter",
          label: "Balance after (Destination)",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        }
      ],
      logsFields: [
        {
          key: "showDetails",
          label: "",
          sortDirection: "desc"
        },
        {
          key: "date",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "ip",
          label: "Ip",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "group",
          label: "Group",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "action",
          label: "Action",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "user",
          label: "Person",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "admin",
          label: "Action by user",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ],
      kingfinLogsFields: [
        {
          key: "eventName",
          label: "Event name",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "externalId",
          label: "External id",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "createdAt",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "status",
          label: "Status",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "payload",
          label: "Payload",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ],
      accountsFields: [
        {
          key: "login",
          label: "Login",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "status",
          label: "Status",
          filtered: true
        },
        {
          key: "balance",
          label: "Balance",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "credit",
          label: "Credit",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "currency",
          label: "Currency",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "leverage",
          label: "Leverage",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "typeTitle",
          label: "Type",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "dateRange",
          label: "Date Create",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "platformTitle",
          label: "Platform",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "serverAccount",
          label: "Account (type)",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "server",
          label: "Server",
          tdClass: "fieldsClass",
          filtered: true
        }
      ],
      dealsFields: [
        {
          key: "login",
          label: "Login",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "deal",
          label: "Deal",
          tdClass: "details-fields-class"
        },
        {
          key: "typeTitle",
          label: "Server type",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "order",
          label: "Order",
          tdClass: "details-fields-class"
        },
        {
          key: "position",
          label: "Position",
          tdClass: "details-fields-class"
        },
        {
          key: "symbol",
          label: "Symbol",
          tdClass: "details-fields-class"
        },
        {
          key: "date",
          label: "Date",
          tdClass: "details-fields-class"
        },
        {
          key: "action",
          label: "Action",
          tdClass: "details-fields-class"
        },
        {
          key: "entry",
          label: "Entry",
          tdClass: "details-fields-class"
        },
        {
          key: "profit",
          label: "Profit",
          tdClass: "details-fields-class"
        },
        {
          key: "commission",
          label: "Commission",
          tdClass: "details-fields-class"
        },
        {
          key: "swap",
          label: "Swap",
          tdClass: "details-fields-class"
        },
        {
          key: "volume",
          label: "Volume",
          tdClass: "details-fields-class"
        },
        {
          key: "price",
          label: "Price",
          tdClass: "details-fields-class"
        },
        {
          key: "contractSize",
          label: "Contract Size",
          tdClass: "details-fields-class"
        }
      ],
      ordersFields: [
        {
          key: "login",
          label: "Login",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "deal",
          label: "Order",
          tdClass: "details-fields-class"
        },
        {
          key: "typeTitle",
          label: "Server type",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "symbol",
          label: "Symbol",
          tdClass: "details-fields-class"
        },
        {
          key: "type",
          label: "Type",
          tdClass: "details-fields-class"
        },
        {
          key: "volume",
          label: "Volume",
          tdClass: "details-fields-class"
        },
        {
          key: "dateOpen",
          label: "Date Open",
          tdClass: "details-fields-class"
        },
        {
          key: "priceOpen",
          label: "Price Open",
          tdClass: "details-fields-class"
        },
        {
          key: "dateClose",
          label: "Date Close",
          tdClass: "details-fields-class"
        },
        {
          key: "priceClose",
          label: "Price Close",
          tdClass: "details-fields-class"
        },
        {
          key: "commission",
          label: "Commission",
          tdClass: "details-fields-class"
        },
        {
          key: "swap",
          label: "Swap",
          tdClass: "details-fields-class"
        },
        {
          key: "taxes",
          label: "Taxes",
          tdClass: "details-fields-class"
        },
        {
          key: "profit",
          label: "Profit",
          tdClass: "details-fields-class"
        }
      ],
      kycFields: [
        {
          key: "lastActionKycDate",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "externalKycStatus",
          label: "KYC status",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "actions",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "15rem !important" }
        }
      ],
      kycDocsFields: [
        {
          key: "kycDocType",
          label: "Type",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "validUntil",
          label: "Valid until",
          sortDirection: "desc",
          tdClass: "dateField"
        }
      ],
      amlFields: [
        {
          key: "groupTitle",
          label: "Group title",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "description",
          label: "Description",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ],
      depositsFields: [
        {
          key: "dateAndTime",
          label: "Request Date&time",
          sortable: true,
          sortDirection: "asc",
          tdClass: "dateField"
        },
        {
          key: "transactionNumber",
          label: "Transaction number",
          sortable: true
        },
        {
          key: "paymentMethod",
          label: "Payment method",
          sortable: true
        },
        {
          key: "amount",
          label: "Requested amount",
          sortable: true
        },
        {
          key: "amountReceived",
          label: "Actual amount",
          sortable: true
        },
        {
          key: "currency",
          label: "Currency",
          sortable: false
        },
        {
          key: "status.status",
          label: "Transaction status",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        }
      ],
      totalDepositsFields: [
        {
          key: "paymentMethodTitle",
          label: "Payment method",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "totalAmount",
          label: "Amount",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "currency",
          label: "Currency",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "actions",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "7rem !important" }
        }
      ],
      totalWithdrawalsFields: [
        {
          key: "login",
          label: "Login",
          sortDirection: "asc",
          tdClass: this.getGroupBorderClass,
          filtered: false
        },
        {
          key: "paymentMethodTitle",
          label: "Payment method",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "totalMinAmount",
          label: "Min amount",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "totalMaxAmount",
          label: "Maximum amount to withdraw",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "currency",
          label: "Currency",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "actions",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "7rem !important" }
        }
      ],
      withdrawalsFields: [
        {
          key: "dateAndTime",
          label: "Request Date&time",
          sortable: true,
          sortDirection: "asc",
          tdClass: "dateField",
          type: "date"
        },
        {
          key: "transactionNumber",
          label: "Transaction Number",
          sortable: true
        },
        {
          key: "paymentMethod",
          label: "Payment method",
          sortable: true
        },
        {
          key: "amount",
          label: "Requested amount",
          sortable: true
        },
        {
          key: "fee",
          label: "Fee",
          sortable: true
        },
        {
          key: "amountReceived",
          label: "Actual amount",
          sortable: true
        },
        {
          key: "currency",
          label: "Currency"
        },
        {
          key: "status.status",
          label: "Transaction status",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        },
        {
          key: "checkStatus",
          label: "Check status",
          sortDirection: "desc"
        }
      ],
      riskScoringFields: [
        {
          key: "question",
          label: "Question"
        },
        {
          key: "answer",
          label: "User's answer"
        },
        {
          key: "weight",
          tdClass: "score-weight",
          label: "Risk scoring"
        }
      ],
      bonusesFields: [
        {
          key: "showDetails",
          label: "",
          sortDirection: "desc"
        },
        {
          key: "userBonusTitle",
          label: "Bonus",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "dateStart",
          type: "date",
          label: "Date start",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "dateExpiration",
          type: "date",
          label: "Date expiration",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "status",
          label: "Status",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "accrualMode",
          label: "Accrual mode",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        }
      ],
      duplicatesFields: [
        {
          key: "user.name",
          label: "User",
          sortable: false
        },
        {
          key: "userOriginal.name",
          label: "Original",
          sortable: false
        },
        {
          key: "date",
          label: "Date",
          sortable: false
        },
        {
          key: "description",
          label: "Description",
          sortable: false
        },
        {
          key: "status",
          label: "Status",
          sortable: false
        },
        {
          key: "comment",
          label: "Comment",
          sortable: false
        },
        {
          key: "admin.name",
          label: "Admin",
          sortable: false
        },
        {
          key: "editDuplicate",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "10rem !important" }
        }
      ],
      commentsFields: [
        {
          key: "date",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "comment",
          label: "Comment",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "admin.name",
          label: "Admin",
          sortable: false
        },
        {
          key: "login",
          label: "Trading account",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        }
      ],
      dealsFiltersModel: [
        {
          key: "typeTitle",
          selected: ""
        }
      ],
      ordersFiltersModel: [
        {
          key: "typeTitle",
          selected: ""
        }
      ],
      sourceFiltersList: []
    };
  },
  mounted: async function () {
    await this.getCountries();
    await this.getUserDetails();
    if (this.hasTradingAccountsRead) await this.getFiltersList();
  },
  computed: {
    ...mapGetters([
      "hasProfileEdit",
      "hasReadLogs",
      "hasUserManage",
      "hasUsersKycRead",
      "hasTradingAccountsRead",
      "hasTransactionsRead",
      "hasChecksRead",
      "hasChangePermissions",
      "hasDisable2Fa",
      "hasDelete",
      "hasResetLock",
      "hasIbManage",
      "hasBonusesRead",
      "hasDuplicatesManage"
    ]),
    getDisableBtnText() {
      return this.resultRecord.isEnabled === "Yes" ? "Disable user" : "Enable user";
    },
    getDisableWithdrawBtnText() {
      return this.resultRecord.isWithdrawEnabled === "Yes" ? "Disable withdraw" : "Enable withdraw";
    },
    isNotVerifiedUser() {
      return this.resultRecord.externalKycStatus === "NotVerified";
    },
    bonusesDetailsFields() {
      return [
        {
          key: "login",
          label: "Trading account"
        },
        {
          key: "amountAccrued",
          label: "Amount accrued"
        },
        {
          key: "amountAccruedTotal",
          label: "Amount accrued total"
        },
        {
          key: "amountPending",
          label: "Amount pending"
        },
        {
          key: "amountReleased",
          label: "Amount released"
        },
        {
          key: "currency",
          label: "Currency"
        }
      ];
    }
  },
  methods: {
    getGroupBorderClass(value, key, item) {
      if (item.index === this.totalWithdrawalsItemRows - 1) return "border-last";

      if (item.index === 0 || item.group !== this.totalWithdrawalsResult[item.index - 1].group) {
        return "border-top";
      }
      return "no-border";
    },
    getDetailsPart(start, end) {
      return this.usersResult.slice(start, end);
    },
    getUserImage() {
      if (Object.keys(this.resultRecord.id).length === 0) return;
      if (this.resultRecord.logo) return this.resultRecord.logo.url;

      return require("../../../assets/user.svg");
    },
    //#region Route buttons
    async onTransactionsBtnClick() {
      await this.$router.push({
        name: "Transactions",
        params: { userId: this.id },
        query: { userId: this.id }
      });
    },
    //#endregion

    //#region Search
    async onDepositsSrchClicked(textValue) {
      this.depositsMask = textValue;

      await this.getDepositsResult().catch(error => {
        console.error(error);
      });
    },
    async onDepositsFilterClicked(value, textMask) {
      this.depositsMask = textMask;

      await this.getDepositsResult().catch(error => {
        console.error(error);
      });
    },
    async onDepositsLoadClicked(value) {
      this.depositsPerPage = value;

      await this.getDepositsResult().catch(error => {
        console.error(error);
      });
    },
    async onDepositsSortClicked(value, sortDesc) {
      this.sortBy = value;
      this.sortDesc = sortDesc;

      await this.getDepositsResult().catch(error => {
        console.error(error);
      });
    },
    async onTotalDepositsSrchClicked(textValue) {
      this.totalDepositsMask = textValue;

      await this.getTotalDepositsResult().catch(error => {
        console.error(error);
      });
    },
    async onTotalWithdrawalsSrchClicked(textValue) {
      this.totalWithdrawalsMask = textValue;

      await this.getTotalWithdrawalsResult().catch(error => {
        console.error(error);
      });
    },
    async onWithdrawalsSrchClicked(textValue) {
      this.withdrawalsMask = textValue;

      await this.getWithdrawalsResult().catch(error => {
        console.error(error);
      });
    },
    async onWithdrawalsFilterClicked(value, textMask) {
      this.withdrawalsMask = textMask;

      await this.getWithdrawalsResult().catch(error => {
        console.error(error);
      });
    },
    async onWithdrawalsLoadClicked(value) {
      this.withdrawalsPerPage = value;

      await this.getWithdrawalsResult().catch(error => {
        console.error(error);
      });
    },
    async onWithdrawalsSortClicked(value, sortDesc) {
      this.sortBy = value;
      this.sortDesc = sortDesc;

      await this.getWithdrawalsResult().catch(error => {
        console.error(error);
      });
    },
    async onTransactionsSrchClicked(textValue) {
      this.transactionsMask = textValue;

      await this.getTransactionsResult().catch(error => {
        console.error(error);
      });
    },
    async onTransactionsFilterClicked(value, textMask) {
      this.transactionsMask = textMask;

      await this.getTransactionsResult().catch(error => {
        console.error(error);
      });
    },
    async onTransactionsLoadClicked(value) {
      this.transactionsPerPage = value;

      await this.getTransactionsResult().catch(error => {
        console.error(error);
      });
    },
    async onLogsSrchClicked(textValue) {
      this.logsMask = textValue;

      await this.getLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onLogsFilterClicked(value, textMask) {
      this.logsMask = textMask;

      await this.getLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onLogsLoadClicked(value) {
      this.logsPerPage = value;

      await this.getLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onKingfinLogsSrchClicked(textValue) {
      this.kingfinLogsMask = textValue;

      await this.getKingfinLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onKingfinLogsPgClicked(value) {
      this.kingfinLogsCurrentPage = value;
      await this.getKingfinLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onKingfinLogsFilterClicked(value, textMask) {
      this.kingfinLogsMask = textMask;

      await this.getKingfinLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onKingfinLogsLoadClicked(value) {
      this.kingfinLogsPerPage = value;

      await this.getKingfinLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onIpsSrchClicked(textValue) {
      this.ipsMask = textValue;

      await this.getIpsResult().catch(error => {
        console.error(error);
      });
    },
    async onIpsFilterClicked(value, textMask) {
      this.ipsMask = textMask;

      await this.getIpsResult().catch(error => {
        console.error(error);
      });
    },
    async onIpsLoadClicked(value) {
      this.ipsPerPage = value;

      await this.getIpsResult().catch(error => {
        console.error(error);
      });
    },
    async onKycSrchClicked(textValue) {
      this.kycMask = textValue;

      await this.getKycResult().catch(error => {
        console.error(error);
      });
    },
    async onKycFilterClicked(value, textMask) {
      this.kycMask = textMask;

      await this.getKycResult().catch(error => {
        console.error(error);
      });
    },
    async onKycLoadClicked(value) {
      this.kycPerPage = value;

      await this.getKycResult().catch(error => {
        console.error(error);
      });
    },
    async onKycDocsSrchClicked(textValue) {
      this.kycDocsMask = textValue;

      await this.getKycDocsResult().catch(error => {
        console.error(error);
      });
    },
    async onKycDocsFilterClicked(value, textMask) {
      this.kycDocsMask = textMask;

      await this.getKycDocsResult().catch(error => {
        console.error(error);
      });
    },
    async onKycDocsLoadClicked(value) {
      this.kycDocsPerPage = value;

      await this.getKycDocsResult().catch(error => {
        console.error(error);
      });
    },
    async onDemoAccountsSrchClicked(textValue) {
      this.demoAccountsMask = textValue;

      await this.getDemoAccountsResult().catch(error => {
        console.error(error);
      });
    },
    async onDemoAccountsFilterClicked(value, textMask) {
      this.demoAccountsMask = textMask;

      await this.getDemoAccountsResult().catch(error => {
        console.error(error);
      });
    },
    async onDemoAccountsLoadClicked(value) {
      this.demoAccountsPerPage = value;

      await this.getDemoAccountsResult().catch(error => {
        console.error(error);
      });
    },
    async onRealAccountsSrchClicked(textValue) {
      this.realAccountsMask = textValue;

      await this.getRealAccountsResult().catch(error => {
        console.error(error);
      });
    },
    async onRealAccountsFilterClicked(value, textMask) {
      this.realAccountsMask = textMask;

      await this.getRealAccountsResult().catch(error => {
        console.error(error);
      });
    },
    async onRealAccountsLoadClicked(value) {
      this.realAccountsPerPage = value;

      await this.getRealAccountsResult().catch(error => {
        console.error(error);
      });
    },
    async onDealsSrchClicked(textValue) {
      this.dealsMask = textValue;

      await this.getDealsResult().catch(error => {
        console.error(error);
      });
    },
    async onDealsLoadClicked(value) {
      this.dealsPerPage = value;

      await this.getDealsResult().catch(error => {
        console.error(error);
      });
    },
    async onOrdersSrchClicked(textValue) {
      this.ordersMask = textValue;

      await this.getOrdersResult().catch(error => {
        console.error(error);
      });
    },
    async onOrdersLoadClicked(value) {
      this.ordersPerPage = value;

      await this.getOrdersResult().catch(error => {
        console.error(error);
      });
    },
    async onAmlSrchClicked(textValue) {
      this.amlMask = textValue;

      await this.getAmlResult().catch(error => {
        console.error(error);
      });
    },
    async onAmlFilterClicked(value, textMask) {
      this.amlMask = textMask;

      await this.getAmlResult().catch(error => {
        console.error(error);
      });
    },
    async onAmlLoadClicked(value) {
      this.amlPerPage = value;

      await this.getAmlResult().catch(error => {
        console.error(error);
      });
    },
    async onBonusesSrchClicked(textValue) {
      this.bonusesMask = textValue;

      await this.getBonusesResult().catch(error => {
        console.error(error);
      });
    },
    async onDuplicatesSrchClicked(textValue) {
      this.duplicatesMask = textValue;

      await this.getDuplicatesResult().catch(error => {
        console.error(error);
      });
    },
    async onDuplicatesLoadClicked(value) {
      this.duplicatesPerPage = value;

      await this.getDuplicatesResult().catch(error => {
        console.error(error);
      });
    },
    async onCommentsSrchClicked(textValue) {
      this.commentsMask = textValue;

      await this.getCommentsResult().catch(error => {
        console.error(error);
      });
    },
    async onCommentsPgClicked(value) {
      this.commentsCurrentPage = value;
      await this.getCommentsResult().catch(error => {
        console.error(error);
      });
    },
    async onCommentsLoadClicked(value) {
      this.commentsPerPage = value;

      await this.getCommentsResult().catch(error => {
        console.error(error);
      });
    },
    //#endregion

    //#region Event handlers
    async onLogsLoad() {
      await this.getLogsResult();
    },
    async onDuplicatesLoad() {
      await this.getDuplicatesResult();
    },
    async onIpsLoad() {
      await this.getIpsResult();
    },
    async onKycLoad() {
      await this.getKycResult();
    },
    async onKycDocsLoad() {
      await this.getKycDocsResult();
    },
    async onDemoAccountsLoad() {
      await this.getDemoAccountsResult();
    },
    async onRealAccountsLoad() {
      await this.getRealAccountsResult();
    },
    async onDealsLoad() {
      await this.getDealsResult();
    },
    async onOrdersLoad() {
      await this.getOrdersResult();
    },
    async onAmlLoad() {
      await this.getAmlResult();
    },
    async onTransactionsLoad() {
      await this.getTransactionsResult();
    },
    async onBonusesLoad() {
      await this.getBonusesResult();
    },
    async onRiskLoad() {
      await this.getRiskScoringResult();
    },
    async onDepositsLoad() {
      await this.getDepositsResult();
      await this.getTotalDepositsResult();
    },
    async onWithdrawalsLoad() {
      await this.getWithdrawalsResult();
      await this.getTotalWithdrawalsResult();
    },
    async onKingfinLogsLoad() {
      await this.getKingfinLogsResult();
    },
    async onCommentsLoad() {
      await this.getCommentsResult();
    },
    //#endregion

    //#region Pagination
    async onLogsPgClicked(value) {
      this.logsCurrentPage = value;
      await this.getLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onIpsPgClicked(value) {
      this.ipsCurrentPage = value;
      await this.getIpsResult().catch(error => {
        console.error(error);
      });
    },
    async onKycPgClicked(value) {
      this.kycCurrentPage = value;
      await this.getKycResult().catch(error => {
        console.error(error);
      });
    },
    async onKycDocsPgClicked(value) {
      this.kycDocsCurrentPage = value;
      await this.getKycDocsResult().catch(error => {
        console.error(error);
      });
    },
    async onDemoAccountsPgClicked(value) {
      this.demoAccountsCurrentPage = value;
      await this.getDemoAccountsResult("Demo").catch(error => {
        console.error(error);
      });
    },
    async onRealAccountsPgClicked(value) {
      this.realAccountsCurrentPage = value;
      await this.getRealAccountsResult().catch(error => {
        console.error(error);
      });
    },
    async onDealsPgClicked(value) {
      this.dealsCurrentPage = value;
      await this.getDealsResult().catch(error => {
        console.error(error);
      });
    },
    async onOrdersPgClicked(value) {
      this.ordersCurrentPage = value;
      await this.getOrdersResult().catch(error => {
        console.error(error);
      });
    },
    async onAmlPgClicked(value) {
      this.amlCurrentPage = value;
      await this.getAmlResult().catch(error => {
        console.error(error);
      });
    },
    async onDepositsPgClicked(value) {
      this.depositsCurrentPage = value;
      await this.getDepositsResult().catch(error => {
        console.error(error);
      });
    },
    async onTotalDepositsPgClicked(value) {
      this.totalDepositsCurrentPage = value;
      await this.getTotalDepositsResult().catch(error => {
        console.error(error);
      });
    },
    async onTotalWithdrawalsPgClicked(value) {
      this.totalWithdrawalsCurrentPage = value;
      await this.getTotalWithdrawalsResult().catch(error => {
        console.error(error);
      });
    },
    async onWithdrawalsPgClicked(value) {
      this.withdrawalsCurrentPage = value;
      await this.getWithdrawalsResult().catch(error => {
        console.error(error);
      });
    },
    async onTransactionsPgClicked(value) {
      this.transactionsCurrentPage = value;
      await this.getTransactionsResult().catch(error => {
        console.error(error);
      });
    },
    async onBonusesPgClicked(value) {
      this.bonusesCurrentPage = value;
      await this.getBonusesResult().catch(error => {
        console.error(error);
      });
    },
    async onDuplicatesPgClicked(value) {
      this.duplicatesCurrentPage = value;
      await this.getDuplicatesResult().catch(error => {
        console.error(error);
      });
    },
    //#endregion

    async onDealsFilterClicked(value, textMask) {
      this.dealsFiltersModel = value;
      this.dealsMask = textMask;

      await this.getDealsResult().catch(error => {
        console.error(error);
      });
    },
    async onOrdersFilterClicked(value, textMask) {
      this.ordersFiltersModel = value;
      this.ordersMask = textMask;

      await this.getOrdersResult().catch(error => {
        console.error(error);
      });
    },
    //#region Update
    async onEmailConfirmBtnClick() {
      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to confirm user email?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await UsersRepository.confirmEmail(this.id);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });
      } else {
        await this.getUserDetails();
        this.flashMessage.success({
          message: "Email confirmed successfully",
          blockClass: "msg-block"
        });
      }
    },
    async onDisableBtnClick() {
      let isEnabled = !(this.resultRecord.isEnabled === "Yes");
      let status = isEnabled ? "enable" : "disable";

      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to " + status + " user?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await UsersRepository.updateUserIsEnabled(this.id, isEnabled);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "User is " + (isEnabled ? "enabled" : "disabled") + " success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onInternalBtnClick() {
      let isInternal = !(this.resultRecord.isInternal === "Yes");
      let status = isInternal ? "internal" : "external";

      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to make user " + status + "?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await UsersRepository.updateUserIsInternal(this.id, isInternal);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "User is " + (isInternal ? "internal" : "external") + " success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onDisableWithdrawBtnClick() {
      let isEnabled = !(this.resultRecord.isWithdrawEnabled === "Yes");
      let status = isEnabled ? "enable" : "disable";

      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to " + status + " withdraw by user?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await UsersRepository.updateUserWithdrawIsEnabled(this.id, isEnabled);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Withdraw is " + (isEnabled ? "enabled" : "disabled") + " success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onDisable2FaBtnClick() {
      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to disable 2Fa?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await UsersRepository.disable2Fa(this.id);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "User 2Fa is disabled success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onDeleteBtnClick() {
      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to delete user?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await UsersRepository.deleteUser(this.id);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "User deleted success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onResetLockBtnClick() {
      let axiosResponse = await UsersRepository.resetLock(this.id);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onReloadCacheBtnClick() {
      let axiosResponse = await UsersRepository.reloadCache(this.id);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onResetKycStatusBtnClick() {
      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to reset KYC status?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await UsersRepository.resetKycStatus(this.id);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });

      await this.getUserDetails();
    },
    async onAddCommentBtnClick() {
      const dlgResult = await this.$refs.addCommentDlg.show({
        message: "Add comment",
        commentRequired: true,
        userEmail: this.resultRecord.email
      });

      if (dlgResult.isOk) {
        let request = {
          value: dlgResult.comment
        };

        let axiosResponse = await UsersRepository.addComment(this.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Comment created",
          blockClass: "msg-block"
        });
      }
      await this.getUserDetails();
    },
    async onDepositLimitCommentClicked(item) {
      const dlgResult = await this.$refs.addCommentDlg.show({
        message: "Add comment",
        commentRequired: true,
        userEmail: this.resultRecord.email,
        currency: item.currency,
        amount: item.totalAmount
      });

      if (dlgResult.isOk) {
        let request = {
          comment: dlgResult.comment,
          currency: item.currency,
          type: "UserDepositPaymentMethod"
        };

        let axiosResponse = await PaymentsRepository.addDepositLimitComment(this.id, item.paymentMethodId, request);

        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Comment created",
          blockClass: "msg-block"
        });
      }
      await this.getUserDetails();
    },
    async onPaymentMethodCommentClicked(item) {
      const dlgResult = await this.$refs.addCommentDlg.show({
        message: "Add comment",
        commentRequired: true,
        userEmail: this.resultRecord.email,
        currency: item.currency,
        amount: item.totalAmount
      });

      if (dlgResult.isOk) {
        let request = {
          comment: dlgResult.comment,
          currency: item.currency,
          type: "UserWithdrawPaymentMethod"
        };

        let axiosResponse = await PaymentsRepository.addPaymentMethodComment(
          item.tradingAccountId,
          item.paymentMethodId,
          request
        );

        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Comment created",
          blockClass: "msg-block"
        });
      }
      await this.getUserDetails();
    },
    async updatePermissions() {
      const dlgResult = await this.$refs.editPermissionsDlg.show({
        rowValue: this.resultRecord
      });

      if (dlgResult.isOk) {
        let request = {
          adminPermissions: dlgResult.permissions
        };

        let axiosResponse = await ManageRepository.updateUserPermissions(this.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Permissions updated",
          blockClass: "msg-block"
        });
      }
      await this.getUserDetails();
    },
    async onCancelDuplicate(comment, userId, fieldId, originalUserId) {
      let request = {
        originalUserId: originalUserId,
        fieldId: fieldId,
        comment: comment ?? null
      };

      let axiosResponse = await UsersRepository.cancelDuplicate(userId, request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      await this.getDuplicatesResult();
      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });
    },
    async onAttachToPartnership() {
      const dlgResult = await this.$refs.attachToPartnershipDlg.show();

      if (dlgResult.isOk) {
        let request = {
          affiliateId: dlgResult.affiliateId,
          offerId: dlgResult.offerId,
          subIdFirst: dlgResult.subIdFirst,
          subIdSecond: dlgResult.subIdSecond,
          subIdThird: dlgResult.subIdThird,
          subIdFourth: dlgResult.subIdFourth,
          subIdFifth: dlgResult.subIdFifth,
          subIdSixth: dlgResult.subIdSixth
        };

        let axiosResponse = await UsersRepository.attachToPartnership(this.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Attached successfully",
          blockClass: "msg-block"
        });
      }
      await this.getUserDetails();
    },
    async onEditProfileBtnClick(item) {
      let firstName = item.name === "First Name" ? item.value : null;
      let lastName = item.name === "Last Name" ? item.value : null;
      let nationality = item.name === "Nationality" ? item.value : null;
      let email = item.name === "Email" ? item.value : null;
      let tag = item.name === "Tag" ? item.value : null;

      let request = {
        firstName: firstName,
        lastName: lastName,
        nationality: nationality,
        email: email,
        tag: tag
      };

      let axiosResponse = await UsersRepository.updateProfile(this.id, request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });
      } else {
        await this.getUserDetails();
        this.flashMessage.success({
          message: "User updated success",
          blockClass: "msg-block"
        });
      }
    },
    async onEditTargetBtnClick(item, setDefault) {
      let request = {
        billingTarget: item.value,
        setDefault: setDefault
      };

      let axiosResponse = await UsersRepository.updateTarget(this.id, request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });
      } else {
        await this.getUserDetails();
        this.flashMessage.success({
          message: "User target updated success",
          blockClass: "msg-block"
        });
      }
    },
    //#endregion

    //#region Get
    async getCountries() {
      let resultInfo = await PlatformRepository.getCountries();

      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      let result = resultInfo.data.items;
      this.countries = result;
    },
    async getUserDetails() {
      let resultInfo = await UsersRepository.getUserDetails(this.id);

      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      let result = convertUserDetails(resultInfo.data);
      this.resultRecord = result;

      this.usersResult = [
        {
          name: "ID code",
          value: this.resultRecord.number
        },
        {
          name: "Client ID",
          value: this.resultRecord.id
        },
        {
          name: "First Name",
          value: this.resultRecord.firstName,
          isEditable: true,
          onEdit: false
        },
        {
          name: "Last Name",
          value: this.resultRecord.lastName,
          isEditable: true,
          onEdit: false
        },
        ...(this.resultRecord.dateOfBirth
          ? [
              {
                name: "Date of birth",
                value: this.resultRecord.dateOfBirth
              }
            ]
          : []),
        ...(this.resultRecord.phone
          ? [
              {
                name: "Phone",
                value: this.resultRecord.phone
              }
            ]
          : []),
        {
          name: "Email",
          value: this.resultRecord.email,
          isEditable: true,
          onEdit: false
        },
        {
          name: "Country",
          value: this.resultRecord.country
        },
        ...(this.resultRecord.city
          ? [
              {
                name: "City",
                value: this.resultRecord.city
              }
            ]
          : []),
        ...(this.resultRecord.street
          ? [
              {
                name: "Street",
                value: this.resultRecord.street
              }
            ]
          : []),
        ...(this.resultRecord.postalCode
          ? [
              {
                name: "Postal code",
                value: this.resultRecord.postalCode
              }
            ]
          : []),
        ...(this.resultRecord.nationality
          ? [
              {
                name: "Nationality",
                value: this.resultRecord.nationality,
                isEditable: true,
                onEdit: false
              }
            ]
          : []),
        {
          name: "Target",
          value: this.resultRecord.billingTarget,
          isTargetEditable: this.hasUserManage,
          onEdit: false
        },
        {
          name: "Registration date",
          value: this.resultRecord.regDate
        },
        {
          name: "Last action date",
          value: this.resultRecord.lastAction
        },
        {
          name: "Tag",
          value: this.resultRecord.tag,
          isEditable: true,
          onEdit: false
        },
        {
          name: "Questionnaire",
          value: this.resultRecord.isSurveyCompleted
        },
        {
          name: "Risk status",
          value: this.resultRecord.riskStatus
        },
        {
          name: "KYC Status",
          value: this.resultRecord.externalKycStatus
        },
        ...(this.resultRecord.sumSubInfo
          ? [
              {
                name: "SumSub info",
                value: this.resultRecord.sumSubInfo
              }
            ]
          : []),
        {
          name: "Enabled",
          value: this.resultRecord.isEnabled
        },
        {
          name: "Deleted",
          value: this.resultRecord.isDeleted
        },
        {
          name: "Internal",
          value: this.resultRecord.isInternal
        },
        {
          name: "Email Confirmed",
          value: this.resultRecord.isEmailConfirmed
        },
        {
          name: "Deposit Enabled",
          value: this.resultRecord.isDepositEnabled
        },
        {
          name: "Withdrawal Enabled",
          value: this.resultRecord.isWithdrawEnabled
        },
        {
          name: "Withdrawal Blocked Until",
          value: this.resultRecord.lockoutWithdrawalsEndDate
        },
        {
          name: "2Fa Enabled",
          value: this.resultRecord.is2FaEnabled
        },
        {
          name: "Admin",
          value: this.resultRecord.isAdmin
        },
        {
          name: "UTM campaign",
          value: this.resultRecord.utmCampaign
        },
        ...(this.resultRecord.clickId
          ? [
              {
                name: "Click ID",
                value: this.resultRecord.clickId
              }
            ]
          : this.hasIbManage
          ? [
              {
                name: "Click ID",
                value: this.resultRecord.clickId,
                isAttached: this.hasIbManage
              }
            ]
          : []),
        ...(this.resultRecord.url
          ? [
              {
                name: "Url",
                value: this.resultRecord.url
              }
            ]
          : [])
      ];
    },
    async getLogsResult() {
      let filter = {
        params: {
          skip: (this.logsCurrentPage - 1) * this.logsPerPage,
          take: this.logsPerPage,
          mask: this.logsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserLogs(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.logsTotalRows = axiosResponse.data.total;
      let logsResponse = axiosResponse.data;
      this.isLoading = false;

      this.logsResult = convertLogsList(logsResponse.items);
    },
    async getKingfinLogsResult() {
      let filter = {
        params: {
          skip: (this.kingfinLogsCurrentPage - 1) * this.kingfinLogsPerPage,
          take: this.kingfinLogsPerPage,
          mask: this.kingfinLogsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserKingFinLogs(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.kingfinLogsTotalRows = axiosResponse.data.total;
      let kingfinLogsResponse = axiosResponse.data;
      this.isLoading = false;

      this.kingfinLogsResult = convertKingFinLogs(kingfinLogsResponse.items);
    },
    async getCommentsResult() {
      let filter = {
        params: {
          skip: (this.commentsCurrentPage - 1) * this.commentsPerPage,
          take: this.commentsPerPage,
          mask: this.commentsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getComments(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.commentsTotalRows = axiosResponse.data.total;
      let commentsResponse = axiosResponse.data;
      this.isLoading = false;

      this.commentsResult = convertComments(commentsResponse.items);
    },
    async getIpsResult() {
      let filter = {
        params: {
          skip: (this.ipsCurrentPage - 1) * this.ipsPerPage,
          take: this.ipsPerPage,
          mask: this.ipsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserIps(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.ipsTotalRows = axiosResponse.data.total;
      let ipsResponse = axiosResponse.data;
      this.isLoading = false;

      this.ipsResult = convertIps(ipsResponse.items);
      console.log("Done!");
    },
    async getKycResult() {
      let filter = {
        params: {
          skip: (this.kycCurrentPage - 1) * this.kycPerPage,
          take: this.kycPerPage,
          mask: this.kycMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserSumSubHistory(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.kycTotalRows = axiosResponse.data.total;
      let kycResponse = axiosResponse.data;
      this.isLoading = false;

      this.kycResult = convertKyc(kycResponse.items);
      await this.getKycInfo();

      console.log("Done!");
    },
    async getKycDocsResult() {
      let filter = {
        params: {
          skip: (this.kycDocsCurrentPage - 1) * this.kycDocsPerPage,
          take: this.kycDocsPerPage,
          mask: this.kycDocsMask,
          userId: this.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserSumSubDocs(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.kycDocsTotalRows = axiosResponse.data.total;
      let kycDocsResponse = axiosResponse.data;
      this.isLoading = false;

      this.kycDocsResult = convertKycDocs(kycDocsResponse.items);
      await this.getKycInfo();

      console.log("Done!");
    },
    async getAmlResult() {
      let filter = {
        params: {
          skip: (this.amlCurrentPage - 1) * this.amlPerPage,
          take: this.amlPerPage,
          mask: this.amlMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserSurveyInfo(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      let amlResponse = axiosResponse.data;
      this.isLoading = false;

      this.amlResult = convertAml(amlResponse.surveyGroups);
      this.riskScore = amlResponse.riskScore ?? 0;

      console.log("Done!");
    },
    async getRiskScoringResult() {
      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserRiskInfo(this.id);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      let riskResponse = axiosResponse.data;
      this.isLoading = false;

      this.firstStageScore = riskResponse.firstStageScore;
      this.firstStageAnswers = riskResponse.firstStageAnswers;
      this.secondStageScore = riskResponse.secondStageScore;
      this.secondStageAnswers = riskResponse.secondStageAnswers;
      this.riskOnboardingResult = riskResponse?.onboarding?.answers;
      this.riskSumSubResult = riskResponse?.sumsub?.answers;
    },
    async getKycInfo() {
      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserSumSubInfo(this.id);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      let kycResponse = axiosResponse.data;
      this.hasApplicant = kycResponse.hasApplicant;
      this.applicantUrl = kycResponse.applicantUrl;

      this.isLoading = false;
      console.log("Done!");
    },
    async getDemoAccountsResult() {
      let filter = {
        params: {
          skip: (this.demoAccountsCurrentPage - 1) * this.demoAccountsPerPage,
          take: this.demoAccountsPerPage,
          mask: this.demoAccountsMask,
          userId: this.id,
          serverType: "Demo"
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getAccounts(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.demoAccountsTotalRows = axiosResponse.data.total;
      let accountsResponse = axiosResponse.data;
      this.isLoading = false;

      this.demoAccountsResult = convertAccountsList(accountsResponse.items);
    },
    async getRealAccountsResult() {
      let filter = {
        params: {
          skip: (this.realAccountsCurrentPage - 1) * this.realAccountsPerPage,
          take: this.realAccountsPerPage,
          mask: this.realAccountsMask,
          userId: this.id,
          serverType: "Real"
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getAccounts(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.realAccountsTotalRows = axiosResponse.data.total;
      let accountsResponse = axiosResponse.data;
      this.isLoading = false;

      this.realAccountsResult = convertAccountsList(accountsResponse.items);
    },
    async getDealsResult() {
      let serverType = this.dealsFiltersModel.find(item => item.key === "typeTitle");

      let filter = {
        params: {
          skip: (this.dealsCurrentPage - 1) * this.dealsPerPage,
          take: this.dealsPerPage,
          mask: this.dealsMask,
          serverType: serverType.selected,
          userId: this.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getMt5Deals(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.dealsTotalRows = axiosResponse.data.total;
      let dealsResponse = axiosResponse.data;
      this.isLoading = false;

      this.dealsResult = convertDeals(dealsResponse.items);
    },
    async getOrdersResult() {
      let serverType = this.ordersFiltersModel.find(item => item.key === "typeTitle");

      let filter = {
        params: {
          skip: (this.ordersCurrentPage - 1) * this.ordersPerPage,
          take: this.ordersPerPage,
          mask: this.ordersMask,
          serverType: serverType.selected,
          userId: this.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getMt4Orders(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.ordersTotalRows = axiosResponse.data.total;
      let ordersResponse = axiosResponse.data;
      this.isLoading = false;

      this.ordersResult = convertOrders(ordersResponse.items);
    },
    async getBonusesResult() {
      let filter = {
        params: {
          skip: (this.bonusesCurrentPage - 1) * this.bonusesPerPage,
          take: this.bonusesPerPage,
          mask: this.bonusesMask,
          userId: this.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getUserBonuses(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.bonusesTotalRows = axiosResponse.data.total;
      let bonusesResponse = axiosResponse.data;
      this.isLoading = false;

      this.bonusesResult = convertUsersBonusesList(bonusesResponse.items);
      console.log("Done!");
    },
    async getDuplicatesResult() {
      let filter = {
        params: {
          skip: (this.duplicatesCurrentPage - 1) * this.duplicatesPerPage,
          take: this.duplicatesPerPage,
          mask: this.duplicatesMask,
          userId: this.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserDuplicates(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.duplicatesTotalRows = axiosResponse.data.total;
      let duplicatesResponse = axiosResponse.data;
      this.isLoading = false;

      this.duplicatesResult = convertDuplicates(duplicatesResponse.items);
    },
    async getDepositsResult() {
      switch (this.sortBy) {
        case "dateAndTime": {
          if (this.sortDesc) this.sorting = "ByDateAndTimeDesc";
          else this.sorting = "ByDateAndTimeAsc";
          break;
        }
        case "clientId": {
          if (this.sortDesc) this.sorting = "ByClientIdDesc";
          else this.sorting = "ByClientIdAsc";
          break;
        }
        case "client": {
          if (this.sortDesc) this.sorting = "ByClientDesc";
          else this.sorting = "ByClientAsc";
          break;
        }
        case "email": {
          if (this.sortDesc) this.sorting = "ByEmailDesc";
          else this.sorting = "ByEmailAsc";
          break;
        }
        case "transactionNumber": {
          if (this.sortDesc) this.sorting = "ByTransactionIdDesc";
          else this.sorting = "ByTransactionIdAsc";
          break;
        }
        case "paymentMethod": {
          if (this.sortDesc) this.sorting = "ByPaymentMethodDesc";
          else this.sorting = "ByPaymentMethodAsc";
          break;
        }
        case "amount": {
          if (this.sortDesc) this.sorting = "ByAmountDesc";
          else this.sorting = "ByAmountAsc";
          break;
        }
        case "status.status": {
          if (this.sortDesc) this.sorting = "ByTransactionStatusDesc";
          else this.sorting = "ByTransactionStatusAsc";
          break;
        }
      }

      let filter = {
        params: {
          skip: (this.depositsCurrentPage - 1) * this.depositsPerPage,
          take: this.depositsPerPage,
          mask: this.depositsMask,
          userId: this.id,
          sorting: this.sorting
        }
      };

      this.isLoading = true;
      let axiosResponse = await ChecksRepository.getDeposits(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.depositsTotalRows = axiosResponse.data.total;
      let depositsResponse = axiosResponse.data;
      this.isLoading = false;

      this.depositsResult = convertList(depositsResponse.items);
    },
    async getTotalDepositsResult() {
      let filter = {
        params: {
          skip: (this.totalDepositsCurrentPage - 1) * this.totalDepositsPerPage,
          take: this.totalDepositsPerPage,
          mask: this.totalDepositsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await PaymentsRepository.getTotalDeposits(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalDepositsTotalRows = axiosResponse.data.total;
      let totalDepositsResponse = axiosResponse.data;
      this.isLoading = false;

      this.totalDepositsResult = convertDepositsList(totalDepositsResponse.items);
    },
    async getWithdrawalsResult() {
      switch (this.sortBy) {
        case "dateAndTime": {
          if (this.sortDesc) this.sorting = "ByDateAndTimeDesc";
          else this.sorting = "ByDateAndTimeAsc";
          break;
        }
        case "clientId": {
          if (this.sortDesc) this.sorting = "ByClientIdDesc";
          else this.sorting = "ByClientIdAsc";
          break;
        }
        case "email": {
          if (this.sortDesc) this.sorting = "ByEmailDesc";
          else this.sorting = "ByEmailAsc";
          break;
        }
        case "transactionNumber": {
          if (this.sortDesc) this.sorting = "ByTransactionIdDesc";
          else this.sorting = "ByTransactionIdAsc";
          break;
        }
        case "paymentMethod": {
          if (this.sortDesc) this.sorting = "ByPaymentMethodDesc";
          else this.sorting = "ByPaymentMethodAsc";
          break;
        }
        case "amount": {
          if (this.sortDesc) this.sorting = "ByAmountDesc";
          else this.sorting = "ByAmountAsc";
          break;
        }
        case "status.status": {
          if (this.sortDesc) this.sorting = "ByTransactionStatusDesc";
          else this.sorting = "ByTransactionStatusAsc";
          break;
        }
      }

      let filter = {
        params: {
          skip: (this.withdrawalsCurrentPage - 1) * this.withdrawalsPerPage,
          take: this.withdrawalsPerPage,
          mask: this.withdrawalsMask,
          userId: this.id,
          sorting: this.sorting
        }
      };

      this.isLoading = true;
      let axiosResponse = await ChecksRepository.getWithdraw(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.withdrawalsTotalRows = axiosResponse.data.total;
      let withdrawalsResponse = axiosResponse.data;
      this.isLoading = false;

      this.withdrawalsResult = convertList(withdrawalsResponse.items);
    },
    async getTotalWithdrawalsResult() {
      let filter = {
        params: {
          skip: (this.totalWithdrawalsCurrentPage - 1) * this.totalWithdrawalsPerPage,
          take: this.totalWithdrawalsPerPage,
          mask: this.totalWithdrawalsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await PaymentsRepository.getTotalWithdrawals(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalWithdrawalsTotalRows = axiosResponse.data.total;
      let totalWithdrawalsResponse = axiosResponse.data;
      this.isLoading = false;

      this.totalWithdrawalsItemRows = totalWithdrawalsResponse.items.length;
      this.totalWithdrawalsResult = convertWithdrawalsList(totalWithdrawalsResponse.items);
    },
    async getTransactionsResult() {
      let filter = {
        params: {
          skip: (this.transactionsCurrentPage - 1) * this.transactionsPerPage,
          take: this.transactionsPerPage,
          mask: this.transactionsMask,
          userId: this.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await TransactionsRepository.getTransactions(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.transactionsTotalRows = axiosResponse.data.total;
      let transactionsResponse = axiosResponse.data;
      this.isLoading = false;

      this.transactionsResult = convertTransactionsList(transactionsResponse.items);
    },
    async onKycDetailsClicked() {
      window.open(this.applicantUrl, "_blank");
    },
    //#endregion
    //#region Filters
    async getFiltersList() {
      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getAccountsFilterInfo();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.sourceFiltersList = [];
      this.sourceFiltersList.push(await this.filters_getServerTypes(filterInfo));
    },
    async filters_getServerTypes(filtersInfo) {
      if (filtersInfo.serverTypes) {
        return {
          key: "typeTitle",
          items: filtersInfo.serverTypes
        };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  @include media-breakpoint-landscape-tablet() {
    display: flex;
    flex-wrap: wrap;
  }
}

.image-wrapper {
  position: relative;
  padding-top: 200px;
  min-width: 250px;
}

.image {
  width: 210px;
  height: 210px;
  left: 50%;
  transform: translateX(-50%);
  top: -80px;
  position: absolute;
}

.image img {
  width: 100%;
  text-align: center;
  max-height: 100%;
  object-fit: cover;
}

.btns {
  display: flex;
}

.risk-title,
.risk-total {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  margin-bottom: 0;
}

.risk-wrapper ::v-deep .vsa-list {
  --vsa-max-width: unset;
}

.risk-wrapper ::v-deep .vsa-item__content {
  text-align: left;
}

.risk-wrapper ::v-deep .vsa-item__trigger__icon {
  transform: scale(0.6);
}

.risk-wrapper ::v-deep .vsa-item__trigger:hover {
  background-color: transparent;
  color: #000;
}
.risk-wrapper ::v-deep .vsa-item__trigger:focus {
  background-color: transparent;
  color: #000;
}

.risk-wrapper ::v-deep .vsa-item__trigger:hover .vsa-item__trigger__icon--is-default:before,
.risk-wrapper ::v-deep .vsa-item__trigger:hover .vsa-item__trigger__icon--is-default:after {
  background-color: #000;
}

.risk-wrapper ::v-deep .vsa-item__trigger:focus .vsa-item__trigger__icon--is-default:before,
.risk-wrapper ::v-deep .vsa-item__trigger:focus .vsa-item__trigger__icon--is-default:after {
  background-color: #000;
}

.level-High {
  background-color: #f4cccc;
}

.level-Medium {
  background-color: #fce5cd;
}

.level-Low {
  /* background-color: #d8ead3; */
}

.riskScoring ::v-deep table {
  margin-top: 0 !important;
}
.riskScoring {
  margin-bottom: 40px;
}
</style>
